* {
  box-sizing: border-box;
}

body {
  background: #FBF9F4;
  margin: 0;
  font-family: 'Inconsolata', monospace;;
  font-size: 22px;
  padding-bottom: 24px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, p {
  font-weight: normal;
  line-height: 1;
  margin: 0;
}

h2 {
  color: #777;
  font-size: 0.9em;
  margin-top: 36px;
  margin-bottom: 8px;
  text-transform: uppercase;
  letter-spacing: 4px;
}

h3 {
  font-size: 1.2em;
  text-align: center;
}

a, a:visited {
  color: #000;
  text-decoration: none;
}

a:hover {
  color: #666;
}